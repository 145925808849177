html:not(.hydrated) body {
  display: block !important;
}
#loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  z-index: 9999;
  opacity: 1;
  transition: opacity 0.5s ease-out;
  &.done {
    opacity: 0;
  }
  &.error #loader,
  &:not(.error) #error-info {
    display: none;
  }
}
#loader {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10%;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  & > svg {
    max-width: min(25%, 25em);
  }
}
#error-info {
  margin: auto;
  max-width: 24em;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#progress-bar {
  width: 50%;
  height: 20px;
  background: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  div {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0%;
    background: #156087;
    transition: width 0.2s ease;
    &.est {
      background: #ccc;
    }
  }
}
.cg-ls-container {
  width: 100%;
  margin: 20px;
  border-radius: 8px;
  text-align: center;
}
.cg-ls-error-message {
  background-color: #fdecec;
  color: #d9534f;
  border: 1px solid #f5c6cb;
  padding: 10px;
  border-radius: 5px;
  margin: 20px;
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  &.unsupported-browser {
    display: flex;
  }
}
.cg-ls-support-message {
  font-size: 18px;
  margin-bottom: 20px;
  color: #6c757d;
  svg {
    max-width: 4em;
  }
}
p.cg-ls-title {
  font-weight: bolder;
  margin: 1.5em;
  margin-inline: 3em;
}
.cg-ls-support-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.cg-ls-support-button {
  padding: 12px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  &.whatsapp-button {
    margin-top: 0.5em;
    background-color: #436580;
    color: #fff;
  }
  &.email-button {
    background-color: #fff;
    color: #436580;
    border: 1px solid #436580;
  }
}
#ls-close-btn {
  margin-top: 20px;
  display: none;
  &.visible {
    display: block;
  }
}
.cg-ls-error-details {
  display: block;
  background-color: #e9ecef;
  color: #495057;
  padding: 10px;
  border-radius: 5px;
  font-size: 12px;
  margin-top: 20px;
  text-align: left;
  word-break: break-all;
}
