:root {
  --ion-color-cogniblue: #156087;
  --ion-color-cogniblue-rgb: 21,96,135;
  --ion-color-cogniblue-contrast: #ffffff;
  --ion-color-cogniblue-contrast-rgb: 255,255,255;
  --ion-color-cogniblue-shade: #125477;
  --ion-color-cogniblue-tint: #2c7093;

  --ion-color-cognibluesh: #5C7F9D;
  --ion-color-cognibluesh-rgb: 92,127,157;
  --ion-color-cognibluesh-contrast: #ffffff;
  --ion-color-cognibluesh-contrast-rgb: 255,255,255;
  --ion-color-cognibluesh-shade: #51708a;
  --ion-color-cognibluesh-tint: #6c8ca7;

  --ion-color-cogniroyalblue: #2F668A;
  --ion-color-cogniroyalblue-rgb: 47,102,138;
  --ion-color-cogniroyalblue-contrast: #ffffff;
  --ion-color-cogniroyalblue-contrast-rgb: 255,255,255;
  --ion-color-cogniroyalblue-shade: #295a79;
  --ion-color-cogniroyalblue-tint: #447596;

  --ion-color-cognicobalt: #3D6A86;
	--ion-color-cognicobalt-rgb: 61,106,134;
	--ion-color-cognicobalt-contrast: #ffffff;
	--ion-color-cognicobalt-contrast-rgb: 255,255,255;
	--ion-color-cognicobalt-shade: #365d76;
	--ion-color-cognicobalt-tint: #507992;

  --ion-color-cognidenin: #2D394D;
	--ion-color-cognidenin-rgb: 45,57,77;
	--ion-color-cognidenin-contrast: #ffffff;
	--ion-color-cognidenin-contrast-rgb: 255,255,255;
	--ion-color-cognidenin-shade: #283244;
	--ion-color-cognidenin-tint: #424d5f;

  --ion-color-cognistone: #47758C;
  --ion-color-cognistone-rgb: 71,117,140;
  --ion-color-cognistone-contrast: #ffffff;
  --ion-color-cognistone-contrast-rgb: 255,255,255;
  --ion-color-cognistone-shade: #3e677b;
  --ion-color-cognistone-tint: #598398;

  --ion-color-cognistonewithchalkforeground: #47758C;
  --ion-color-cognistonewithchalkforeground-rgb: 71,117,140;
  --ion-color-cognistonewithchalkforeground-contrast: #fcfcfc;
  --ion-color-cognistonewithchalkforeground-contrast-rgb: 255,255,255;
  --ion-color-cognistonewithchalkforeground-shade: #3e677b;
  --ion-color-cognistonewithchalkforeground-tint: #598398;

  --ion-color-cognisteel: #527593;
  --ion-color-cognisteel-rgb: 82,117,147;
  --ion-color-cognisteel-contrast: #ffffff;
  --ion-color-cognisteel-contrast-rgb: 255,255,255;
  --ion-color-cognisteel-shade: #486781;
  --ion-color-cognisteel-tint: #63839e;

  --ion-color-cognispruce: #2E4B59;
  --ion-color-cognispruce-rgb: 46,75,89;
  --ion-color-cognispruce-contrast: #ffffff;
  --ion-color-cognispruce-contrast-rgb: 255,255,255;
  --ion-color-cognispruce-shade: #28424e;
  --ion-color-cognispruce-tint: #435d6a;

  --ion-color-cognicyprus: #455A64;
  --ion-color-cognicyprus-rgb: 69,90,100;
  --ion-color-cognicyprus-contrast: #ffffff;
  --ion-color-cognicyprus-contrast-rgb: 255,255,255;
  --ion-color-cognicyprus-shade: #3d4f58;
  --ion-color-cognicyprus-tint: #586b74;

  --ion-color-cognislate: #6B9DB8;
  --ion-color-cognislate-rgb: 107,157,184;
  --ion-color-cognislate-contrast: #000000;
  --ion-color-cognislate-contrast-rgb: 0,0,0;
  --ion-color-cognislate-shade: #5e8aa2;
  --ion-color-cognislate-tint: #7aa7bf;

  --ion-color-cognisapphire: #92C1D3;
  --ion-color-cognisapphire-rgb: 146,193,211;
  --ion-color-cognisapphire-contrast: #000000;
  --ion-color-cognisapphire-contrast-rgb: 0,0,0;
  --ion-color-cognisapphire-shade: #80aaba;
  --ion-color-cognisapphire-tint: #9dc7d7;

  --ion-color-cognisapphirewhiteforeground: #92C1D3;
  --ion-color-cognisapphirewhiteforeground-rgb: 146,193,211;
  --ion-color-cognisapphirewhiteforeground-contrast: #FFFFFF;
  --ion-color-cognisapphirewhiteforeground-contrast-rgb: 255,255,255;
  --ion-color-cognisapphirewhiteforeground-shade: #80aaba;
  --ion-color-cognisapphirewhiteforeground-tint: #9dc7d7;

  --ion-color-cognipigeon: #9DB6C8;
  --ion-color-cognipigeon-rgb: 157,182,200;
  --ion-color-cognipigeon-contrast: #000000;
  --ion-color-cognipigeon-contrast-rgb: 0,0,0;
  --ion-color-cognipigeon-shade: #8aa0b0;
  --ion-color-cognipigeon-tint: #a7bdce;

  --ion-color-cognicharcoal: #383838;
  --ion-color-cognicharcoal-rgb: 56,56,56;
  --ion-color-cognicharcoal-contrast: #ffffff;
  --ion-color-cognicharcoal-contrast-rgb: 255,255,255;
  --ion-color-cognicharcoal-shade: #313131;
  --ion-color-cognicharcoal-tint: #4c4c4c;

  --ion-color-cogniiron: #5C5C5C;
  --ion-color-cogniiron-rgb: 92,92,92;
  --ion-color-cogniiron-contrast: #ffffff;
  --ion-color-cogniiron-contrast-rgb: 255,255,255;
  --ion-color-cogniiron-shade: #515151;
  --ion-color-cogniiron-tint: #6c6c6c;

  --ion-color-cognishadow: #888888;
  --ion-color-cognishadow-rgb: 136,136,136;
  --ion-color-cognishadow-contrast: #000000;
  --ion-color-cognishadow-contrast-rgb: 0,0,0;
  --ion-color-cognishadow-shade: #787878;
  --ion-color-cognishadow-tint: #949494;

  --ion-color-cognismoke: #5B5B5B;
  --ion-color-cognismoke-rgb: 91,91,91;
  --ion-color-cognismoke-contrast: #ffffff;
  --ion-color-cognismoke-contrast-rgb: 255,255,255;
  --ion-color-cognismoke-shade: #505050;
  --ion-color-cognismoke-tint: #6b6b6b;

  --ion-color-cognigrey: #A5A5A5;
  --ion-color-cognigrey-rgb: 165,165,165;
  --ion-color-cognigrey-contrast: #000000;
  --ion-color-cognigrey-contrast-rgb: 0,0,0;
  --ion-color-cognigrey-shade: #919191;
  --ion-color-cognigrey-tint: #aeaeae;

  --ion-color-cognisilver: #D7D7D7;
  --ion-color-cognisilver-rgb: 215,215,215;
  --ion-color-cognisilver-contrast: #000000;
  --ion-color-cognisilver-contrast-rgb: 0,0,0;
  --ion-color-cognisilver-shade: #bdbdbd;
  --ion-color-cognisilver-tint: #dbdbdb;

  --ion-color-cogniskeleton: #A6A6A6;
  --ion-color-cogniskeleton-rgb: 166,166,166;
  --ion-color-cogniskeleton-contrast: #000000;
  --ion-color-cogniskeleton-contrast-rgb: 0,0,0;
  --ion-color-cogniskeleton-shade: #929292;
  --ion-color-cogniskeleton-tint: #afafaf;

  --ion-color-cognirhino: #B9B9B9;
	--ion-color-cognirhino-rgb: 185,185,185;
	--ion-color-cognirhino-contrast: #000000;
	--ion-color-cognirhino-contrast-rgb: 0,0,0;
	--ion-color-cognirhino-shade: #a3a3a3;
	--ion-color-cognirhino-tint: #c0c0c0;

  --ion-color-cognisnow: #F3F0F0;
  --ion-color-cognisnow-rgb: 243,240,240;
  --ion-color-cognisnow-contrast: #000000;
  --ion-color-cognisnow-contrast-rgb: 0,0,0;
  --ion-color-cognisnow-shade: #d6d3d3;
  --ion-color-cognisnow-tint: #f4f2f2;

  --ion-color-cognisnowwithcharcoalforeground: #F3F0F0;
  --ion-color-cognisnowwithcharcoalforeground-rgb: 243,240,240;
  --ion-color-cognisnowwithcharcoalforeground-contrast: #383838;
  --ion-color-cognisnowwithcharcoalforeground-contrast-rgb: 56,56,56;
  --ion-color-cognisnowwithcharcoalforeground-shade: #d6d3d3;
  --ion-color-cognisnowwithcharcoalforeground-tint: #f4f2f2;

  --ion-color-cognifog: #EFEDED;
  --ion-color-cognifog-rgb: 239,237,237;
  --ion-color-cognifog-contrast: #000000;
  --ion-color-cognifog-contrast-rgb: 0,0,0;
  --ion-color-cognifog-shade: #d2d1d1;
  --ion-color-cognifog-tint: #f1efef;

  --ion-color-cognifogwithcharcoalforeground: #EFEDED;
  --ion-color-cognifogwithcharcoalforeground-rgb: 239,237,237;
  --ion-color-cognifogwithcharcoalforeground-contrast: #383838;
  --ion-color-cognifogwithcharcoalforeground-contrast-rgb: 56,56,56;
  --ion-color-cognifogwithcharcoalforeground-shade: #d2d1d1;
  --ion-color-cognifogwithcharcoalforeground-tint: #f1efef;

  --ion-color-cognicloud: #EAEAEA;
  --ion-color-cognicloud-rgb: 234,234,234;
  --ion-color-cognicloud-contrast: #000000;
  --ion-color-cognicloud-contrast-rgb: 0,0,0;
  --ion-color-cognicloud-shade: #cecece;
  --ion-color-cognicloud-tint: #ececec;

  --ion-color-cognichalk: #FCFCFC;
  --ion-color-cognichalk-rgb: 252,252,252;
  --ion-color-cognichalk-contrast: #000000;
  --ion-color-cognichalk-contrast-rgb: 0,0,0;
  --ion-color-cognichalk-shade: #dedede;
  --ion-color-cognichalk-tint: #fcfcfc;

  --ion-color-cognichalkwithgreyforeground: #FCFCFC;
  --ion-color-cognichalkwithgreyforeground-rgb: 252,252,252;
  --ion-color-cognichalkwithgreyforeground-contrast: #A5A5A5;
  --ion-color-cognichalkwithgreyforeground-contrast-rgb: 165,165,165;
  --ion-color-cognichalkwithgreyforeground-shade: #dedede;
  --ion-color-cognichalkwithgreyforeground-tint: #fcfcfc;

  --ion-color-cognichalkwithblueshforeground: #FCFCFC;
  --ion-color-cognichalkwithblueshforeground-rgb: 252,252,252;
  --ion-color-cognichalkwithblueshforeground-contrast: #5C7F9D;
  --ion-color-cognichalkwithblueshforeground-contrast-rgb: 92,127,157;
  --ion-color-cognichalkwithblueshforeground-shade: #dedede;
  --ion-color-cognichalkwithblueshforeground-tint: #dbdbdb;

  --ion-color-cognimilk: #FEFEFE;
  --ion-color-cognimilk-rgb: 254,254,254;
  --ion-color-cognimilk-contrast: #000000;
  --ion-color-cognimilk-contrast-rgb: 0,0,0;
  --ion-color-cognimilk-shade: #e0e0e0;
  --ion-color-cognimilk-tint: #fefefe;

  --ion-color-cognimilkwithsmokeforeground: #FEFEFE;
  --ion-color-cognimilkwithsmokeforeground-rgb: 254,254,254;
  --ion-color-cognimilkwithsmokeforeground-contrast: #5B5B5B;
  --ion-color-cognimilkwithsmokeforeground-contrast-rgb: 91,91,91;
  --ion-color-cognimilkwithsmokeforeground-shade: #e0e0e0;
  --ion-color-cognimilkwithsmokeforeground-tint: #fefefe;

  --ion-color-cognimilkwithrhinoforeground: #FEFEFE;
  --ion-color-cognimilkwithrhinoforeground-rgb: 254,254,254;
  --ion-color-cognimilkwithrhinoforeground-contrast: #B9B9B9;
  --ion-color-cognimilkwithrhinoforeground-contrast-rgb: 185,185,185;
  --ion-color-cognimilkwithrhinoforeground-shade: #e0e0e0;
  --ion-color-cognimilkwithrhinoforeground-tint: #fefefe;
  
  --ion-color-cognidaisy: #fdfdfd;
	--ion-color-cognidaisy-rgb: 253,253,253;
	--ion-color-cognidaisy-contrast: #000000;
	--ion-color-cognidaisy-contrast-rgb: 0,0,0;
	--ion-color-cognidaisy-shade: #dfdfdf;
	--ion-color-cognidaisy-tint: #fdfdfd;

  --ion-color-cognidaisywithgreyforeground: #fdfdfd;
  --ion-color-cognidaisywithgreyforeground-rgb: 252,252,252;
  --ion-color-cognidaisywithgreyforeground-contrast: #A5A5A5;
  --ion-color-cognidaisywithgreyforeground-contrast-rgb: 165,165,165;
  --ion-color-cognidaisywithgreyforeground-shade: #dfdfdf;
  --ion-color-cognidaisywithgreyforeground-tint: #fdfdfd;

  --ion-color-cognired: #B0302F;
  --ion-color-cognired-rgb: 176,48,47;
  --ion-color-cognired-contrast: #ffffff;
  --ion-color-cognired-contrast-rgb: 255,255,255;
  --ion-color-cognired-shade: #9b2a29;
  --ion-color-cognired-tint: #b84544;

  --ion-color-cogniredish: #FF725E;
  --ion-color-cogniredish-rgb: 255,114,94;
  --ion-color-cogniredish-contrast: #FCFCFC;
  --ion-color-cogniredish-contrast-rgb: 255,255,255;
  --ion-color-cogniredish-shade: #e06453;
  --ion-color-cogniredish-tint: #ff806e;

  --ion-color-cognidarkred: #BA1B1B;
	--ion-color-cognidarkred-rgb: 186,27,27;
	--ion-color-cognidarkred-contrast: #ffffff;
	--ion-color-cognidarkred-contrast-rgb: 255,255,255;
	--ion-color-cognidarkred-shade: #a41818;
	--ion-color-cognidarkred-tint: #c13232;

  --ion-color-cognifrenchrose: #EF637D;
	--ion-color-cognifrenchrose-rgb: 239,99,125;
	--ion-color-cognifrenchrose-contrast: #FCFCFC;
	--ion-color-cognifrenchrose-contrast-rgb: 255,255,255;
	--ion-color-cognifrenchrose-shade: #d2576e;
	--ion-color-cognifrenchrose-tint: #f1738a;

  --ion-color-cognibubblegum: #D588A6;
  --ion-color-cognibubblegum-rgb: 213,136,166;
  --ion-color-cognibubblegum-contrast: #000000;
  --ion-color-cognibubblegum-contrast-rgb: 0,0,0;
  --ion-color-cognibubblegum-shade: #bb7892;
  --ion-color-cognibubblegum-tint: #d994af;

  --ion-color-cognioldrose: #F1ABA9;
  --ion-color-cognioldrose-rgb: 241,171,169;
  --ion-color-cognioldrose-contrast: #000000;
  --ion-color-cognioldrose-contrast-rgb: 0,0,0;
  --ion-color-cognioldrose-shade: #d49695;
  --ion-color-cognioldrose-tint: #f2b3b2;

  --ion-color-cogniblush: #FCD4D4;
  --ion-color-cogniblush-rgb: 252,212,212;
  --ion-color-cogniblush-contrast: #000000;
  --ion-color-cogniblush-contrast-rgb: 0,0,0;
  --ion-color-cogniblush-shade: #debbbb;
  --ion-color-cogniblush-tint: #fcd8d8;

  --ion-color-cognicrepe: #F2DCDE;
  --ion-color-cognicrepe-rgb: 242,220,222;
  --ion-color-cognicrepe-contrast: #000000;
  --ion-color-cognicrepe-contrast-rgb: 0,0,0;
  --ion-color-cognicrepe-shade: #d5c2c3;
  --ion-color-cognicrepe-tint: #f3e0e1;

  --ion-color-cognilavender: #F5D3C6;
  --ion-color-cognilavender-rgb: 245,211,198;
  --ion-color-cognilavender-contrast: #000000;
  --ion-color-cognilavender-contrast-rgb: 0,0,0;
  --ion-color-cognilavender-shade: #d8baae;
  --ion-color-cognilavender-tint: #f6d7cc;

  --ion-color-cognicoconutwhite: #F2F2F2;
  --ion-color-cognicoconutwhite-rgb: 242,242,242;
  --ion-color-cognicoconutwhite-contrast: #000000;
  --ion-color-cognicoconutwhite-contrast-rgb: 0,0,0;
  --ion-color-cognicoconutwhite-shade: #d5d5d5;
  --ion-color-cognicoconutwhite-tint: #f3f3f3;

  --ion-color-cognigreen: #759400;
  --ion-color-cognigreen-rgb: 117,148,0;
  --ion-color-cognigreen-contrast: #ffffff;
  --ion-color-cognigreen-contrast-rgb: 255,255,255;
  --ion-color-cognigreen-shade: #678200;
  --ion-color-cognigreen-tint: #839f1a;

  --ion-color-cogniteal: #3C9294;
  --ion-color-cogniteal-rgb: 60,146,148;
  --ion-color-cogniteal-contrast: #ffffff;
  --ion-color-cogniteal-contrast-rgb: 255,255,255;
  --ion-color-cogniteal-shade: #358082;
  --ion-color-cogniteal-tint: #509d9f;

  --ion-color-cognitealwithchalkforeground: #3C9294;
  --ion-color-cognitealwithchalkforeground-rgb: 60,146,148;
  --ion-color-cognitealwithchalkforeground-contrast: #fcfcfc;
  --ion-color-cognitealwithchalkforeground-contrast-rgb: 252,252,252;
  --ion-color-cognitealwithchalkforeground-shade: #358082;
  --ion-color-cognitealwithchalkforeground-tint: #509d9f;
  
  --ion-color-cognipine: #455A64;
  --ion-color-cognipine-rgb: 69,90,100;
  --ion-color-cognipine-contrast: #ffffff;
  --ion-color-cognipine-contrast-rgb: 255,255,255;
  --ion-color-cognipine-shade: #3d4f58;
  --ion-color-cognipine-tint: #586b74;

  --ion-color-cognipinewithchalkforeground: #455A64;
  --ion-color-cognipinewithchalkforeground-rgb: 69,90,100;
  --ion-color-cognipinewithchalkforeground-contrast: #fcfcfc;
  --ion-color-cognipinewithchalkforeground-contrast-rgb: 252,252,252;
  --ion-color-cognipinewithchalkforeground-shade: #3d4f58;
  --ion-color-cognipinewithchalkforeground-tint: #586b74;

  --ion-color-cogniolive: #9DB543;
  --ion-color-cogniolive-rgb: 157,181,67;
  --ion-color-cogniolive-contrast: #000000;
  --ion-color-cogniolive-contrast-rgb: 0,0,0;
  --ion-color-cogniolive-shade: #8a9f3b;
  --ion-color-cogniolive-tint: #a7bc56;

  --ion-color-cognipear: #BCD94E;
  --ion-color-cognipear-rgb: 188,217,78;
  --ion-color-cognipear-contrast: #000000;
  --ion-color-cognipear-contrast-rgb: 0,0,0;
  --ion-color-cognipear-shade: #a5bf45;
  --ion-color-cognipear-tint: #c3dd60;

  --ion-color-cognilime: #C8E753;
  --ion-color-cognilime-rgb: 200,231,83;
  --ion-color-cognilime-contrast: #000000;
  --ion-color-cognilime-contrast-rgb: 0,0,0;
  --ion-color-cognilime-shade: #b0cb49;
  --ion-color-cognilime-tint: #cee964;

  --ion-color-cognihunter: #0AA2A0;
  --ion-color-cognihunter-rgb: 10,162,160;
  --ion-color-cognihunter-contrast: #ffffff;
  --ion-color-cognihunter-contrast-rgb: 255,255,255;
  --ion-color-cognihunter-shade: #098f8d;
  --ion-color-cognihunter-tint: #23abaa;

  --ion-color-cogniorange: #FFAE52;
  --ion-color-cogniorange-rgb: 255,174,82;
  --ion-color-cogniorange-contrast: #ffffff;
  --ion-color-cogniorange-contrast-rgb: 255,255,255;
  --ion-color-cogniorange-shade: #e09948;
  --ion-color-cogniorange-tint: #ffb663;

  --ion-color-cognipumpkin: #FE7E1D;
  --ion-color-cognipumpkin-rgb: 254,126,29;
  --ion-color-cognipumpkin-contrast: #FFFFFF;
  --ion-color-cognipumpkin-contrast-rgb: 0,0,0;
  --ion-color-cognipumpkin-shade: #e06f1a;
  --ion-color-cognipumpkin-tint: #fe8b34;

  --ion-color-cognitumeric: #FB9F11;
  --ion-color-cognitumeric-rgb: 251,159,17;
  --ion-color-cognitumeric-contrast: #FFFFFF;
  --ion-color-cognitumeric-contrast-rgb: 255,255,255;
  --ion-color-cognitumeric-shade: #dd8c0f;
  --ion-color-cognitumeric-tint: #fba929;

  --ion-color-honey: #FFB038;
  --ion-color-honey-rgb: 255,176,56;
  --ion-color-honey-contrast: #000000;
  --ion-color-honey-contrast-rgb: 0,0,0;
  --ion-color-honey-shade: #e09b31;
  --ion-color-honey-tint: #ffb84c;

  --ion-color-cognimustard: #E7CE57;
  --ion-color-cognimustard-rgb: 231,206,87;
  --ion-color-cognimustard-contrast: #000000;
  --ion-color-cognimustard-contrast-rgb: 0,0,0;
  --ion-color-cognimustard-shade: #cbb54d;
  --ion-color-cognimustard-tint: #e9d368;

  --ion-color-cogniyellow: #F19700;
	--ion-color-cogniyellow-rgb: 241,151,0;
	--ion-color-cogniyellow-contrast: #FFFFFF;
	--ion-color-cogniyellow-contrast-rgb: 255,255,255;
	--ion-color-cogniyellow-shade: #d48500;
	--ion-color-cogniyellow-tint: #f2a11a;

  --ion-color-cogniterracotta: #FF7152;
  --ion-color-cogniterracotta-rgb: 255,113,82;
  --ion-color-cogniterracotta-contrast: #FFFFFF;
  --ion-color-cogniterracotta-contrast-rgb: 255,255,255;
  --ion-color-cogniterracotta-shade: #e06348;
  --ion-color-cogniterracotta-tint: #ff7f63;

  --ion-color-cogniwhite: #ffffff;
  --ion-color-cogniwhite-rgb: 255,255,255;
  --ion-color-cogniwhite-contrast: #000000;
  --ion-color-cogniwhite-contrast-rgb: 0,0,0;
  --ion-color-cogniwhite-shade: #e0e0e0;
  --ion-color-cogniwhite-tint: #ffffff;

  --ion-color-cogniwhitewithsmokeforeground: #ffffff;
  --ion-color-cogniwhitewithsmokeforeground-rgb: 255,255,255;
  --ion-color-cogniwhitewithsmokeforeground-contrast: #5B5B5B;
  --ion-color-cogniwhitewithsmokeforeground-contrast-rgb: 91,91,91;
  --ion-color-cogniwhitewithsmokeforeground-shade: #e0e0e0;
  --ion-color-cogniwhitewithsmokeforeground-tint: #ffffff;

  --ion-color-cogniwhitewithblueshforeground: #ffffff;
  --ion-color-cogniwhitewithblueshforeground-rgb: 255,255,255;
  --ion-color-cogniwhitewithblueshforeground-contrast: #5C7F9D;
  --ion-color-cogniwhitewithblueshforeground-contrast-rgb: 92,127,157;
  --ion-color-cogniwhitewithblueshforeground-shade: #e0e0e0;
  --ion-color-cogniwhitewithblueshforeground-tint: #ffffff;

  --ion-color-cogniwhitewithgreyforeground: #ffffff;
  --ion-color-cogniwhitewithgreyforeground-rgb: 252,252,252;
  --ion-color-cogniwhitewithgreyforeground-contrast: #A5A5A5;
  --ion-color-cogniwhitewithgreyforeground-contrast-rgb: 165,165,165;
  --ion-color-cogniwhitewithgreyforeground-shade: #e0e0e0;
  --ion-color-cogniwhitewithgreyforeground-tint: #ffffff;

  --ion-color-cogniblack: #000000;
  --ion-color-cogniblack-rgb: 0,0,0;
  --ion-color-cogniblack-contrast: #ffffff;
  --ion-color-cogniblack-contrast-rgb: 255,255,255;
  --ion-color-cogniblack-shade: #000000;
  --ion-color-cogniblack-tint: #1a1a1a;

  --ion-color-cognimoodmostpositive: #E4CB4D;
  --ion-color-cognimoodmostpositive-rgb: 228,203,77;
  --ion-color-cognimoodmostpositive-contrast: #ffffff;
  --ion-color-cognimoodmostpositive-contrast-rgb: 255,255,255;
  --ion-color-cognimoodmostpositive-shade: #c9b344;
  --ion-color-cognimoodmostpositive-tint: #e7d05f;

  --ion-color-cognimoodpositive: #5C7F9D;
  --ion-color-cognimoodpositive-rgb: 92,127,157;
  --ion-color-cognimoodpositive-contrast: #ffffff;
  --ion-color-cognimoodpositive-contrast-rgb: 255,255,255;
  --ion-color-cognimoodpositive-shade: #51708a;
  --ion-color-cognimoodpositive-tint: #6c8ca7;

  --ion-color-cognimoodneutral: #6B9DB8;
  --ion-color-cognimoodneutral-rgb: 107,157,184;
  --ion-color-cognimoodneutral-contrast: #ffffff;
  --ion-color-cognimoodneutral-contrast-rgb: 255,255,255;
  --ion-color-cognimoodneutral-shade: #5e8aa2;
  --ion-color-cognimoodneutral-tint: #7aa7bf;

  --ion-color-cognimoodnegative: #9A8AB2;
  --ion-color-cognimoodnegative-rgb: 154,138,178;
  --ion-color-cognimoodnegative-contrast: #ffffff;
  --ion-color-cognimoodnegative-contrast-rgb: 255,255,255;
  --ion-color-cognimoodnegative-shade: #88799d;
  --ion-color-cognimoodnegative-tint: #a496ba;

  --ion-color-cognimoodmostnegative: #DF92B0;
  --ion-color-cognimoodmostnegative-rgb: 223,146,176;
  --ion-color-cognimoodmostnegative-contrast: #ffffff;
  --ion-color-cognimoodmostnegative-contrast-rgb: 255,255,255;
  --ion-color-cognimoodmostnegative-shade: #c4809b;
  --ion-color-cognimoodmostnegative-tint: #e29db8;

  --ion-color-cogniplaceholderok: rgba(0, 0, 0, 0.3);
  --ion-color-cogniplaceholdererror: rgba(176, 48, 47, 0.3);
}

.ion-color-cogniblue {
  --ion-color-base: var(--ion-color-cogniblue);
  --ion-color-base-rgb: var(--ion-color-cogniblue-rgb);
  --ion-color-contrast: var(--ion-color-cogniblue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cogniblue-contrast-rgb);
  --ion-color-shade: var(--ion-color-cogniblue-shade);
  --ion-color-tint: var(--ion-color-cogniblue-tint);
}

.ion-color-cognibluesh {
  --ion-color-base: var(--ion-color-cognibluesh);
  --ion-color-base-rgb: var(--ion-color-cognibluesh-rgb);
  --ion-color-contrast: var(--ion-color-cognibluesh-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognibluesh-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognibluesh-shade);
  --ion-color-tint: var(--ion-color-cognibluesh-tint);
}

.ion-color-cogniroyalblue {
	--ion-color-base: var(--ion-color-cogniroyalblue);
	--ion-color-base-rgb: var(--ion-color-cogniroyalblue-rgb);
	--ion-color-contrast: var(--ion-color-cogniroyalblue-contrast);
	--ion-color-contrast-rgb: var(--ion-color-cogniroyalblue-contrast-rgb);
	--ion-color-shade: var(--ion-color-cogniroyalblue-shade);
	--ion-color-tint: var(--ion-color-cogniroyalblue-tint);
}

.ion-color-cognicobalt {
	--ion-color-base: var(--ion-color-cognicobalt);
	--ion-color-base-rgb: var(--ion-color-cognicobalt-rgb);
	--ion-color-contrast: var(--ion-color-cognicobalt-contrast);
	--ion-color-contrast-rgb: var(--ion-color-cognicobalt-contrast-rgb);
	--ion-color-shade: var(--ion-color-cognicobalt-shade);
	--ion-color-tint: var(--ion-color-cognicobalt-tint);
}

.ion-color-cognidenin {
	--ion-color-base: var(--ion-color-cognidenin);
	--ion-color-base-rgb: var(--ion-color-cognidenin-rgb);
	--ion-color-contrast: var(--ion-color-cognidenin-contrast);
	--ion-color-contrast-rgb: var(--ion-color-cognidenin-contrast-rgb);
	--ion-color-shade: var(--ion-color-cognidenin-shade);
	--ion-color-tint: var(--ion-color-cognidenin-tint);
}

.ion-color-cognistone {
  --ion-color-base: var(--ion-color-cognistone);
  --ion-color-base-rgb: var(--ion-color-cognistone-rgb);
  --ion-color-contrast: var(--ion-color-cognistone-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognistone-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognistone-shade);
  --ion-color-tint: var(--ion-color-cognistone-tint);
}

.ion-color-cognistonewithchalkforeground {
  --ion-color-base: var(--ion-color-cognistonewithchalkforeground);
  --ion-color-base-rgb: var(--ion-color-cognistonewithchalkforeground-rgb);
  --ion-color-contrast: var(--ion-color-cognistonewithchalkforeground-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognistonewithchalkforeground-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognistonewithchalkforeground-shade);
  --ion-color-tint: var(--ion-color-cognistonewithchalkforeground-tint);
}

.ion-color-cognisteel {
  --ion-color-base: var(--ion-color-cognisteel);
  --ion-color-base-rgb: var(--ion-color-cognisteel-rgb);
  --ion-color-contrast: var(--ion-color-cognisteel-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognisteel-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognisteel-shade);
  --ion-color-tint: var(--ion-color-cognisteel-tint);
}

.ion-color-cognispruce {
  --ion-color-base: var(--ion-color-cognispruce);
  --ion-color-base-rgb: var(--ion-color-cognispruce-rgb);
  --ion-color-contrast: var(--ion-color-cognispruce-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognispruce-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognispruce-shade);
  --ion-color-tint: var(--ion-color-cognispruce-tint);
}

.ion-color-cognicyprus {
  --ion-color-base: var(--ion-color-cognicyprus);
  --ion-color-base-rgb: var(--ion-color-cognicyprus-rgb);
  --ion-color-contrast: var(--ion-color-cognicyprus-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognicyprus-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognicyprus-shade);
  --ion-color-tint: var(--ion-color-cognicyprus-tint);
}

.ion-color-cognislate {
  --ion-color-base: var(--ion-color-cognislate);
  --ion-color-base-rgb: var(--ion-color-cognislate-rgb);
  --ion-color-contrast: var(--ion-color-cognislate-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognislate-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognislate-shade);
  --ion-color-tint: var(--ion-color-cognislate-tint);
}

.ion-color-cognisapphire {
  --ion-color-base: var(--ion-color-cognisapphire);
  --ion-color-base-rgb: var(--ion-color-cognisapphire-rgb);
  --ion-color-contrast: var(--ion-color-cognisapphire-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognisapphire-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognisapphire-shade);
  --ion-color-tint: var(--ion-color-cognisapphire-tint);
}

.ion-color-cognisapphirewhiteforeground {
  --ion-color-base: var(--ion-color-cognisapphirewhiteforeground);
  --ion-color-base-rgb: var(--ion-color-cognisapphirewhiteforeground-rgb);
  --ion-color-contrast: var(--ion-color-cognisapphirewhiteforeground-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognisapphirewhiteforeground-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognisapphirewhiteforeground-shade);
  --ion-color-tint: var(--ion-color-cognisapphirewhiteforeground-tint);
}

.ion-color-cognipigeon {
	--ion-color-base: var(--ion-color-cognipigeon);
	--ion-color-base-rgb: var(--ion-color-cognipigeon-rgb);
	--ion-color-contrast: var(--ion-color-cognipigeon-contrast);
	--ion-color-contrast-rgb: var(--ion-color-cognipigeon-contrast-rgb);
	--ion-color-shade: var(--ion-color-cognipigeon-shade);
	--ion-color-tint: var(--ion-color-cognipigeon-tint);
}

.ion-color-cognicharcoal {
  --ion-color-base: var(--ion-color-cognicharcoal);
  --ion-color-base-rgb: var(--ion-color-cognicharcoal-rgb);
  --ion-color-contrast: var(--ion-color-cognicharcoal-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognicharcoal-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognicharcoal-shade);
  --ion-color-tint: var(--ion-color-cognicharcoal-tint);
}

.ion-color-cogniiron {
	--ion-color-base: var(--ion-color-cogniiron);
	--ion-color-base-rgb: var(--ion-color-cogniiron-rgb);
	--ion-color-contrast: var(--ion-color-cogniiron-contrast);
	--ion-color-contrast-rgb: var(--ion-color-cogniiron-contrast-rgb);
	--ion-color-shade: var(--ion-color-cogniiron-shade);
	--ion-color-tint: var(--ion-color-cogniiron-tint);
}

.ion-color-cognishadow {
  --ion-color-base: var(--ion-color-cognishadow);
  --ion-color-base-rgb: var(--ion-color-cognishadow-rgb);
  --ion-color-contrast: var(--ion-color-cognishadow-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognishadow-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognishadow-shade);
  --ion-color-tint: var(--ion-color-cognishadow-tint);
}

.ion-color-cognismoke {
  --ion-color-base: var(--ion-color-cognismoke);
  --ion-color-base-rgb: var(--ion-color-cognismoke-rgb);
  --ion-color-contrast: var(--ion-color-cognismoke-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognismoke-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognismoke-shade);
  --ion-color-tint: var(--ion-color-cognismoke-tint);
}

.ion-color-cognigrey {
  --ion-color-base: var(--ion-color-cognigrey);
  --ion-color-base-rgb: var(--ion-color-cognigrey-rgb);
  --ion-color-contrast: var(--ion-color-cognigrey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognigrey-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognigrey-shade);
  --ion-color-tint: var(--ion-color-cognigrey-tint);
}

.ion-color-cognisilver {
  --ion-color-base: var(--ion-color-cognisilver);
  --ion-color-base-rgb: var(--ion-color-cognisilver-rgb);
  --ion-color-contrast: var(--ion-color-cognisilver-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognisilver-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognisilver-shade);
  --ion-color-tint: var(--ion-color-cognisilver-tint);
}

.ion-color-cogniskeleton {
  --ion-color-base: var(--ion-color-cogniskeleton);
  --ion-color-base-rgb: var(--ion-color-cogniskeleton-rgb);
  --ion-color-contrast: var(--ion-color-cogniskeleton-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cogniskeleton-contrast-rgb);
  --ion-color-shade: var(--ion-color-cogniskeleton-shade);
  --ion-color-tint: var(--ion-color-cogniskeleton-tint);
}

.ion-color-cognisnow {
  --ion-color-base: var(--ion-color-cognisnow);
  --ion-color-base-rgb: var(--ion-color-cognisnow-rgb);
  --ion-color-contrast: var(--ion-color-cognisnow-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognisnow-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognisnow-shade);
  --ion-color-tint: var(--ion-color-cognisnow-tint);
}

.ion-color-cognisnowwithcharcoalforeground {
  --ion-color-base: var(--ion-color-cognisnowwithcharcoalforeground);
  --ion-color-base-rgb: var(--ion-color-cognisnowwithcharcoalforeground-rgb);
  --ion-color-contrast: var(--ion-color-cognisnowwithcharcoalforeground-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognisnowwithcharcoalforeground-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognisnowwithcharcoalforeground-shade);
  --ion-color-tint: var(--ion-color-cognisnowwithcharcoalforeground-tint);
}

.ion-color-cognifog {
  --ion-color-base: var(--ion-color-cognifog);
  --ion-color-base-rgb: var(--ion-color-cognifog-rgb);
  --ion-color-contrast: var(--ion-color-cognifog-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognifog-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognifog-shade);
  --ion-color-tint: var(--ion-color-cognifog-tint);
}

.ion-color-cognifogwithcharcoalforeground {
  --ion-color-base: var(--ion-color-cognifogwithcharcoalforeground);
  --ion-color-base-rgb: var(--ion-color-cognifogwithcharcoalforeground-rgb);
  --ion-color-contrast: var(--ion-color-cognifogwithcharcoalforeground-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognifogwithcharcoalforeground-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognifogwithcharcoalforeground-shade);
  --ion-color-tint: var(--ion-color-cognifogwithcharcoalforeground-tint);
}

.ion-color-cognicloud {
  --ion-color-base: var(--ion-color-cognicloud);
  --ion-color-base-rgb: var(--ion-color-cognicloud-rgb);
  --ion-color-contrast: var(--ion-color-cognicloud-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognicloud-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognicloud-shade);
  --ion-color-tint: var(--ion-color-cognicloud-tint);
}

.ion-color-cognichalk {
  --ion-color-base: var(--ion-color-cognichalk);
  --ion-color-base-rgb: var(--ion-color-cognichalk-rgb);
  --ion-color-contrast: var(--ion-color-cognichalk-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognichalk-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognichalk-shade);
  --ion-color-tint: var(--ion-color-cognichalk-tint);
}

.ion-color-cognichalkwithgreyforeground {
  --ion-color-base: var(--ion-color-cognichalkwithgreyforeground);
  --ion-color-base-rgb: var(--ion-color-cognichalkwithgreyforeground-rgb);
  --ion-color-contrast: var(--ion-color-cognichalkwithgreyforeground-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognichalkwithgreyforeground-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognichalkwithgreyforeground-shade);
  --ion-color-tint: var(--ion-color-cognichalkwithgreyforeground-tint);
}

.ion-color-cognichalkwithblueshforeground {
  --ion-color-base: var(--ion-color-cognichalkwithblueshforeground);
  --ion-color-base-rgb: var(--ion-color-cognichalkwithblueshforeground-rgb);
  --ion-color-contrast: var(--ion-color-cognichalkwithblueshforeground-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognichalkwithblueshforeground-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognichalkwithblueshforeground-shade);
  --ion-color-tint: var(--ion-color-cognichalkwithblueshforeground-tint);
}

.ion-color-cognimilk {
  --ion-color-base: var(--ion-color-cognimilk);
  --ion-color-base-rgb: var(--ion-color-cognimilk-rgb);
  --ion-color-contrast: var(--ion-color-cognimilk-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognimilk-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognimilk-shade);
  --ion-color-tint: var(--ion-color-cognimilk-tint);
}

.ion-color-cognimilkwithsmokeforeground {
  --ion-color-base: var(--ion-color-cognimilkwithsmokeforeground);
  --ion-color-base-rgb: var(--ion-color-cognimilkwithsmokeforeground-rgb);
  --ion-color-contrast: var(--ion-color-cognimilkwithsmokeforeground-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognimilkwithsmokeforeground-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognimilkwithsmokeforeground-shade);
  --ion-color-tint: var(--ion-color-cognimilkwithsmokeforeground-tint);
}

.ion-color-cognimilkwithrhinoforeground {
  --ion-color-base: var(--ion-color-cognimilkwithrhinoforeground);
  --ion-color-base-rgb: var(--ion-color-cognimilkwithrhinoforeground-rgb);
  --ion-color-contrast: var(--ion-color-cognimilkwithrhinoforeground-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognimilkwithrhinoforeground-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognimilkwithrhinoforeground-shade);
  --ion-color-tint: var(--ion-color-cognimilkwithrhinoforeground-tint);
}

.ion-color-cognidaisy {
	--ion-color-base: var(--ion-color-cognidaisy);
	--ion-color-base-rgb: var(--ion-color-cognidaisy-rgb);
	--ion-color-contrast: var(--ion-color-cognidaisy-contrast);
	--ion-color-contrast-rgb: var(--ion-color-cognidaisy-contrast-rgb);
	--ion-color-shade: var(--ion-color-cognidaisy-shade);
	--ion-color-tint: var(--ion-color-cognidaisy-tint);
}

.ion-color-cognidaisywithgreyforeground {
	--ion-color-base: var(--ion-color-cognidaisywithgreyforeground);
	--ion-color-base-rgb: var(--ion-color-cognidaisywithgreyforeground-rgb);
	--ion-color-contrast: var(--ion-color-cognidaisywithgreyforeground-contrast);
	--ion-color-contrast-rgb: var(--ion-color-cognidaisywithgreyforeground-contrast-rgb);
	--ion-color-shade: var(--ion-color-cognidaisywithgreyforeground-shade);
	--ion-color-tint: var(--ion-color-cognidaisywithgreyforeground-tint);
}

.ion-color-cognired {
  --ion-color-base: var(--ion-color-cognired);
  --ion-color-base-rgb: var(--ion-color-cognired-rgb);
  --ion-color-contrast: var(--ion-color-cognired-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognired-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognired-shade);
  --ion-color-tint: var(--ion-color-cognired-tint);
}

.ion-color-cogniredish {
	--ion-color-base: var(--ion-color-cogniredish);
	--ion-color-base-rgb: var(--ion-color-cogniredish-rgb);
	--ion-color-contrast: var(--ion-color-cogniredish-contrast);
	--ion-color-contrast-rgb: var(--ion-color-cogniredish-contrast-rgb);
	--ion-color-shade: var(--ion-color-cogniredish-shade);
	--ion-color-tint: var(--ion-color-cogniredish-tint);
}

.ion-color-cognidarkred {
	--ion-color-base: var(--ion-color-cognidarkred);
	--ion-color-base-rgb: var(--ion-color-cognidarkred-rgb);
	--ion-color-contrast: var(--ion-color-cognidarkred-contrast);
	--ion-color-contrast-rgb: var(--ion-color-cognidarkred-contrast-rgb);
	--ion-color-shade: var(--ion-color-cognidarkred-shade);
	--ion-color-tint: var(--ion-color-cognidarkred-tint);
}

.ion-color-cognifrenchrose {
	--ion-color-base: var(--ion-color-cognifrenchrose);
	--ion-color-base-rgb: var(--ion-color-cognifrenchrose-rgb);
	--ion-color-contrast: var(--ion-color-cognifrenchrose-contrast);
	--ion-color-contrast-rgb: var(--ion-color-cognifrenchrose-contrast-rgb);
	--ion-color-shade: var(--ion-color-cognifrenchrose-shade);
	--ion-color-tint: var(--ion-color-cognifrenchrose-tint);
}

.ion-color-cognibubblegum {
  --ion-color-base: var(--ion-color-cognibubblegum);
  --ion-color-base-rgb: var(--ion-color-cognibubblegum-rgb);
  --ion-color-contrast: var(--ion-color-cognibubblegum-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognibubblegum-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognibubblegum-shade);
  --ion-color-tint: var(--ion-color-cognibubblegum-tint);
}

.ion-color-cognioldrose {
  --ion-color-base: var(--ion-color-cognioldrose);
  --ion-color-base-rgb: var(--ion-color-cognioldrose-rgb);
  --ion-color-contrast: var(--ion-color-cognioldrose-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognioldrose-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognioldrose-shade);
  --ion-color-tint: var(--ion-color-cognioldrose-tint);
}

.ion-color-cogniblush {
  --ion-color-base: var(--ion-color-cogniblush);
  --ion-color-base-rgb: var(--ion-color-cogniblush-rgb);
  --ion-color-contrast: var(--ion-color-cogniblush-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cogniblush-contrast-rgb);
  --ion-color-shade: var(--ion-color-cogniblush-shade);
  --ion-color-tint: var(--ion-color-cogniblush-tint);
}

.ion-color-cognicrepe {
  --ion-color-base: var(--ion-color-cognicrepe);
  --ion-color-base-rgb: var(--ion-color-cognicrepe-rgb);
  --ion-color-contrast: var(--ion-color-cognicrepe-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognicrepe-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognicrepe-shade);
  --ion-color-tint: var(--ion-color-cognicrepe-tint);
}

.ion-color-cognilavender {
	--ion-color-base: var(--ion-color-cognilavender);
	--ion-color-base-rgb: var(--ion-color-cognilavender-rgb);
	--ion-color-contrast: var(--ion-color-cognilavender-contrast);
	--ion-color-contrast-rgb: var(--ion-color-cognilavender-contrast-rgb);
	--ion-color-shade: var(--ion-color-cognilavender-shade);
	--ion-color-tint: var(--ion-color-cognilavender-tint);
}

.ion-color-cognicoconutwhite {
  --ion-color-base: var(--ion-color-cognicoconutwhite);
  --ion-color-base-rgb: var(--ion-color-cognicoconutwhite-rgb);
  --ion-color-contrast: var(--ion-color-cognicoconutwhite-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognicoconutwhite-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognicoconutwhite-shade);
  --ion-color-tint: var(--ion-color-cognicoconutwhite-tint);
}

.ion-color-cognigreen {
  --ion-color-base: var(--ion-color-cognigreen);
  --ion-color-base-rgb: var(--ion-color-cognigreen-rgb);
  --ion-color-contrast: var(--ion-color-cognigreen-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognigreen-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognigreen-shade);
  --ion-color-tint: var(--ion-color-cognigreen-tint);
}

.ion-color-cogniteal {
	--ion-color-base: var(--ion-color-cogniteal);
	--ion-color-base-rgb: var(--ion-color-cogniteal-rgb);
	--ion-color-contrast: var(--ion-color-cogniteal-contrast);
	--ion-color-contrast-rgb: var(--ion-color-cogniteal-contrast-rgb);
	--ion-color-shade: var(--ion-color-cogniteal-shade);
	--ion-color-tint: var(--ion-color-cogniteal-tint);
}

.ion-color-cognitealwithchalkforeground {
	--ion-color-base: var(--ion-color-cognitealwithchalkforeground);
	--ion-color-base-rgb: var(--ion-color-cognitealwithchalkforeground-rgb);
	--ion-color-contrast: var(--ion-color-cognitealwithchalkforeground-contrast);
	--ion-color-contrast-rgb: var(--ion-color-cognitealwithchalkforeground-contrast-rgb);
	--ion-color-shade: var(--ion-color-cognitealwithchalkforeground-shade);
	--ion-color-tint: var(--ion-color-cognitealwithchalkforeground-tint);
}

.ion-color-cognipine {
	--ion-color-base: var(--ion-color-cognipine);
	--ion-color-base-rgb: var(--ion-color-cognipine-rgb);
	--ion-color-contrast: var(--ion-color-cognipine-contrast);
	--ion-color-contrast-rgb: var(--ion-color-cognipine-contrast-rgb);
	--ion-color-shade: var(--ion-color-cognipine-shade);
	--ion-color-tint: var(--ion-color-cognipine-tint);
}

.ion-color-cognipinewithchalkforeground {
	--ion-color-base: var(--ion-color-cognipinewithchalkforeground);
	--ion-color-base-rgb: var(--ion-color-cognipinewithchalkforeground-rgb);
	--ion-color-contrast: var(--ion-color-cognipinewithchalkforeground-contrast);
	--ion-color-contrast-rgb: var(--ion-color-cognipinewithchalkforeground-contrast-rgb);
	--ion-color-shade: var(--ion-color-cognipinewithchalkforeground-shade);
	--ion-color-tint: var(--ion-color-cognipinewithchalkforeground-tint);
}

.ion-color-cogniolive {
  --ion-color-base: var(--ion-color-cogniolive);
  --ion-color-base-rgb: var(--ion-color-cogniolive-rgb);
  --ion-color-contrast: var(--ion-color-cogniolive-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cogniolive-contrast-rgb);
  --ion-color-shade: var(--ion-color-cogniolive-shade);
  --ion-color-tint: var(--ion-color-cogniolive-tint);
}

.ion-color-cognipear {
  --ion-color-base: var(--ion-color-cognipear);
  --ion-color-base-rgb: var(--ion-color-cognipear-rgb);
  --ion-color-contrast: var(--ion-color-cognipear-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognipear-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognipear-shade);
  --ion-color-tint: var(--ion-color-cognipear-tint);
}

.ion-color-cognilime {
  --ion-color-base: var(--ion-color-cognilime);
  --ion-color-base-rgb: var(--ion-color-cognilime-rgb);
  --ion-color-contrast: var(--ion-color-cognilime-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognilime-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognilime-shade);
  --ion-color-tint: var(--ion-color-cognilime-tint);
}

.ion-color-cognihunter {
	--ion-color-base: var(--ion-color-cognihunter);
	--ion-color-base-rgb: var(--ion-color-cognihunter-rgb);
	--ion-color-contrast: var(--ion-color-cognihunter-contrast);
	--ion-color-contrast-rgb: var(--ion-color-cognihunter-contrast-rgb);
	--ion-color-shade: var(--ion-color-cognihunter-shade);
	--ion-color-tint: var(--ion-color-cognihunter-tint);
}

.ion-color-cogniorange {
  --ion-color-base: var(--ion-color-cogniorange);
  --ion-color-base-rgb: var(--ion-color-cogniorange-rgb);
  --ion-color-contrast: var(--ion-color-cogniorange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cogniorange-contrast-rgb);
  --ion-color-shade: var(--ion-color-cogniorange-shade);
  --ion-color-tint: var(--ion-color-cogniorange-tint);
}

.ion-color-cogniyellow {
	--ion-color-base: var(--ion-color-cogniyellow);
	--ion-color-base-rgb: var(--ion-color-cogniyellow-rgb);
	--ion-color-contrast: var(--ion-color-cogniyellow-contrast);
	--ion-color-contrast-rgb: var(--ion-color-cogniyellow-contrast-rgb);
	--ion-color-shade: var(--ion-color-cogniyellow-shade);
	--ion-color-tint: var(--ion-color-cogniyellow-tint);
}

.ion-color-cogniterracotta {
	--ion-color-base: var(--ion-color-cogniterracotta);
	--ion-color-base-rgb: var(--ion-color-cogniterracotta-rgb);
	--ion-color-contrast: var(--ion-color-cogniterracotta-contrast);
	--ion-color-contrast-rgb: var(--ion-color-cogniterracotta-contrast-rgb);
	--ion-color-shade: var(--ion-color-cogniterracotta-shade);
	--ion-color-tint: var(--ion-color-cogniterracotta-tint);
}

.ion-color-cognipumpkin {
  --ion-color-base: var(--ion-color-cognipumpkin);
  --ion-color-base-rgb: var(--ion-color-cognipumpkin-rgb);
  --ion-color-contrast: var(--ion-color-cognipumpkin-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognipumpkin-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognipumpkin-shade);
  --ion-color-tint: var(--ion-color-cognipumpkin-tint);
}

.ion-color-cognitumeric {
	--ion-color-base: var(--ion-color-cognitumeric);
	--ion-color-base-rgb: var(--ion-color-cognitumeric-rgb);
	--ion-color-contrast: var(--ion-color-cognitumeric-contrast);
	--ion-color-contrast-rgb: var(--ion-color-cognitumeric-contrast-rgb);
	--ion-color-shade: var(--ion-color-cognitumeric-shade);
	--ion-color-tint: var(--ion-color-cognitumeric-tint);
}

.ion-color-cognihoney {
  --ion-color-base: var(--ion-color-honey);
  --ion-color-base-rgb: var(--ion-color-honey-rgb);
  --ion-color-contrast: var(--ion-color-honey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-honey-contrast-rgb);
  --ion-color-shade: var(--ion-color-honey-shade);
  --ion-color-tint: var(--ion-color-honey-tint);
}

.ion-color-cognimustard {
  --ion-color-base: var(--ion-color-cognimustard);
  --ion-color-base-rgb: var(--ion-color-cognimustard-rgb);
  --ion-color-contrast: var(--ion-color-cognimustard-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognimustard-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognimustard-shade);
  --ion-color-tint: var(--ion-color-cognimustard-tint);
}

.ion-color-cogniwhite {
  --ion-color-base: var(--ion-color-cogniwhite);
  --ion-color-base-rgb: var(--ion-color-cogniwhite-rgb);
  --ion-color-contrast: var(--ion-color-cogniwhite-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cogniwhite-contrast-rgb);
  --ion-color-shade: var(--ion-color-cogniwhite-shade);
  --ion-color-tint: var(--ion-color-cogniwhite-tint);
}

.ion-color-cogniwhitewithsmokeforeground {
  --ion-color-base: var(--ion-color-cogniwhitewithsmokeforeground);
  --ion-color-base-rgb: var(--ion-color-cogniwhitewithsmokeforeground-rgb);
  --ion-color-contrast: var(--ion-color-cogniwhitewithsmokeforeground-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cogniwhitewithsmokeforeground-contrast-rgb);
  --ion-color-shade: var(--ion-color-cogniwhitewithsmokeforeground-shade);
  --ion-color-tint: var(--ion-color-cogniwhitewithsmokeforeground-tint);
}

.ion-color-cogniwhitewithblueshforeground {
  --ion-color-base: var(--ion-color-cogniwhitewithblueshforeground);
  --ion-color-base-rgb: var(--ion-color-cogniwhitewithblueshforeground-rgb);
  --ion-color-contrast: var(--ion-color-cogniwhitewithblueshforeground-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cogniwhitewithblueshforeground-contrast-rgb);
  --ion-color-shade: var(--ion-color-cogniwhitewithblueshforeground-shade);
  --ion-color-tint: var(--ion-color-cogniwhitewithblueshforeground-tint);
}

.ion-color-cogniwhitewithgreyforeground {
  --ion-color-base: var(--ion-color-cogniwhitewithgreyforeground);
  --ion-color-base-rgb: var(--ion-color-cogniwhitewithgreyforeground-rgb);
  --ion-color-contrast: var(--ion-color-cogniwhitewithgreyforeground-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cogniwhitewithgreyforeground-contrast-rgb);
  --ion-color-shade: var(--ion-color-cogniwhitewithgreyforeground-shade);
  --ion-color-tint: var(--ion-color-cogniwhitewithgreyforeground-tint);
}

.ion-color-cogniblack {
  --ion-color-base: var(--ion-color-cogniblack);
  --ion-color-base-rgb: var(--ion-color-cogniblack-rgb);
  --ion-color-contrast: var(--ion-color-cogniblack-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cogniblack-contrast-rgb);
  --ion-color-shade: var(--ion-color-cogniblack-shade);
  --ion-color-tint: var(--ion-color-cogniblack-tint);
}

.ion-color-cognimoodmostpositive {
  --ion-color-base: var(--ion-color-cognimoodmostpositive);
  --ion-color-base-rgb: var(--ion-color-cognimoodmostpositive-rgb);
  --ion-color-contrast: var(--ion-color-cognimoodmostpositive-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognimoodmostpositive-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognimoodmostpositive-shade);
  --ion-color-tint: var(--ion-color-cognimoodmostpositive-tint);
}

.ion-color-cognimoodpositive {
  --ion-color-base: var(--ion-color-cognimoodpositive);
  --ion-color-base-rgb: var(--ion-color-cognimoodpositive-rgb);
  --ion-color-contrast: var(--ion-color-cognimoodpositive-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognimoodpositive-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognimoodpositive-shade);
  --ion-color-tint: var(--ion-color-cognimoodpositive-tint);
}

.ion-color-cognimoodneutral {
  --ion-color-base: var(--ion-color-cognimoodneutral);
  --ion-color-base-rgb: var(--ion-color-cognimoodneutral-rgb);
  --ion-color-contrast: var(--ion-color-cognimoodneutral-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognimoodneutral-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognimoodneutral-shade);
  --ion-color-tint: var(--ion-color-cognimoodneutral-tint);
}

.ion-color-cognimoodnegative {
  --ion-color-base: var(--ion-color-cognimoodnegative);
  --ion-color-base-rgb: var(--ion-color-cognimoodnegative-rgb);
  --ion-color-contrast: var(--ion-color-cognimoodnegative-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognimoodnegative-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognimoodnegative-shade);
  --ion-color-tint: var(--ion-color-cognimoodnegative-tint);
}

.ion-color-cognimoodmostnegative {
  --ion-color-base: var(--ion-color-cognimoodmostnegative);
  --ion-color-base-rgb: var(--ion-color-cognimoodmostnegative-rgb);
  --ion-color-contrast: var(--ion-color-cognimoodmostnegative-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognimoodmostnegative-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognimoodmostnegative-shade);
  --ion-color-tint: var(--ion-color-cognimoodmostnegative-tint);
}
