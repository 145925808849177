.slc {
  border: 2px solid var(--ion-color-cognigrey);
  box-sizing: border-box;
  color: #000000;
}

.slc-birthday {
  border: 2px solid var(--ion-color-cognisilver);
}

.ng-select.slc {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ng-select.slc .ng-select-container {
  border: none;
  outline: none;
  box-shadow: none;
}

.ng-select.slc-birthday .ng-select-container {
  min-height: unset;
  background-color: var(--ion-color-cognichalk);
}

.ng-select.slc .ng-select-container .ng-placeholder {
  color: var(--ion-color-cogniplaceholderok);
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  border: none;
  box-shadow: none;
}

.ng-select.slc .ng-dropdown-panel {
  border: 2px solid var(--ion-color-cognigrey);
  width: calc(100% + 4px);
  margin-top: 0;
  margin-left: -2px;
}

.ng-select.slc-birthday .ng-dropdown-panel {
  border: 2px solid var(--ion-color-cognisilver);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: var(--ion-color-cognisilver);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background-color: white;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option.ng-option-marked {
  background-color: var(--ion-color-cognisilver);
}

.ng-select.slc .ng-arrow-wrapper {
  position: relative;
  right: 23px;
  height: 15px;
  width: 30px;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
}

.ng-select .ng-arrow-wrapper::before, 
.ng-select .ng-arrow-wrapper::after {
  content: "";
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 100%;
}

.ng-select .ng-arrow-wrapper::before {
  background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 20 12' width='20' height='12' xmlns='http://www.w3.org/2000/svg'><g><path d='M9.79034 11.5868L0 1.79637L1.79637 0L9.79034 7.99381L17.7843 0L19.5807 1.79637L9.79034 11.5868Z' fill='5B5B5B'/></g></svg>") no-repeat;
  background-position: right 5px top 50%;
}

.ng-select.ng-select-opened .ng-arrow-wrapper::before {
  background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 20 12' width='20' height='12' xmlns='http://www.w3.org/2000/svg'><g><path d='M10 1.51533e-07L0 10.1396L1.83484 12L10 3.72112L18.1652 12L20 10.1396L10 1.51533e-07Z' fill='5B5B5B'/></g></svg>") no-repeat;
  background-position: right 5px top 50%;
}
