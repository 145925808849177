ion-content {
  --background: var(--ion-color-cognichalk);
}

ion-item {
  --background: var(--ion-color-cognichalk);
}

ion-button {
  text-transform: none;
  --border-radius: 0;
  border-radius: 0;
  --box-shadow: 0 0 0 0;
  --border-width: 0;
}

ion-footer {
  position: absolute;
  bottom: var(--ion-safe-area-bottom, 0);
}

.list-md, .list-ios {
  padding: 0;
}

ion-loading.cogni-loading > div {
  --background: transparent;
  box-shadow: none !important;
}
