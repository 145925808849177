@media (max-width: 575.98px) {
  .account-situation-modal {
    &::part(content){
      width: 80%;
      height: 450px;
    }
  }

  .tupp-modal {
    &::part(content){
      width: 100%;
      height: 100%;
    }
  }

  .multi-archive-modal {
    &::part(content){
      background-color: var(--ion-color-cognichalk);
    }
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .account-situation-modal {
    &::part(content){
      width: 70%;
      height: 450px;
    }
  }

  .tupp-modal {
    &::part(content){
      width: 450px;
      height: 90%;
    }
  }
  
  .multi-archive-modal {
    &::part(content){
      background-color: var(--ion-color-cognichalk);
    }
  }
}

@media (max-width: 991.98px) {
  .record-modal {
  	&::part(content){
      width: 100%;
      height: 100%;
    }
  }

  .how-to-share-modal {
  	&::part(content){
      width: 100%;
      height: 100%;
    }
  }
}

@media (min-width: 992px) {
  .record-modal {
  	&::part(content){
      width: 865px;
      height: 800px;
    }
  }

  .how-to-share-modal {
  	&::part(content){
      width: 100%;
      height: 90%;
    }
  }

  .account-situation-modal {
    &::part(content){
      width: 600px;
      height: 450px;
    }
  }

  .multi-archive-modal {
    &::part(content){
      background-color: var(--ion-color-cognichalk);
      width: 865px;
      height: 90%;
    }
  }

  .tupp-modal {
    &::part(content){
      width: 900px;
      height: 90%;
    }
  }
}

@media (min-width: 1200px) {
  .record-modal {
  	&::part(content){
      width: 865px;
      height: 800px;
    }
  }

  .how-to-share-modal {
  	&::part(content){
      width: 1239px;
    }
  }

  .multi-archive-modal {
    &::part(content){
      background-color: var(--ion-color-cognichalk);
      width: 1000px;
      height: 90%;
    }
  }
}
















.language-modal {
  --backdrop-opacity: 0.8;
  --height: 280px;
  --width: 280px;
  --background: unset;
  --border-radius: 0px;
}

.banner-modal {
  --backdrop-opacity: 0.6;
  --height: 85%;
  --width: 90%;
  --background: unset;
  --border-radius: 0px;
}

.talktous-modal {
  --width: 450px;
  --height: 500px;
  --background: unset;
  --border-radius: 0px;
  &::part(content){
    position: absolute;
    right: 80px;
    bottom: 80px;
  }
}

.mhs-nps-survey-modal {
  --width: 550px;
  --height: 600px;
  --background: unset;
  --border-radius: 0px;
}

.matchmaking-teaser-modal {
  --backdrop-opacity: 0.8;
}

@media (min-width: 375.02px) {
  .subscribe-to-print-modal {
    --border-radius: 5px;
    --background: unset;
    --box-shadow: 8px 8px 4px 0px rgba(0, 0, 0, 0.15);
  }

  .welcome-modal {
    --border-radius: 5px;
    --background: unset;
    --box-shadow: 8px 8px 4px 0px rgba(0, 0, 0, 0.15);
  }

  .patient-quota-control-modal {
    --border-radius: 5px;
    --background: unset;
    --box-shadow: 8px 8px 4px 0px rgba(0, 0, 0, 0.15);
  }

  .adjust-plan-modal {
    --border-radius: 5px;
    --box-shadow: 8px 8px 4px 0px rgba(0, 0, 0, 0.15);
    --background: unset;
  }

  .how-to-archive-modal {
    --border-radius: 5px;
    --box-shadow: 8px 8px 4px 0px rgba(0, 0, 0, 0.15);
    --background: unset;
  }

  .archive-control-modal {
    --border-radius: 5px;
    --box-shadow: 8px 8px 4px 0px rgba(0, 0, 0, 0.15);
    --background: unset;
  }

  .video-modal {
    --border-radius: 5px;
    --box-shadow: 8px 8px 4px 0px rgba(0, 0, 0, 0.15);
    --background: unset;
  }

  .matchmaking-teaser-modal {
    --border-radius: 5px;
    --box-shadow: 8px 8px 4px 0px rgba(0, 0, 0, 0.15);
    --background: unset;
  }
}

@media (max-width: 991.98px) {
  .subscribe-to-print-modal {
    --max-width: 375px;
    --max-height: 740px;
    --width: 100%;
    --height: 100%;
    --background: unset;
  }

  .welcome-modal {
    --max-width: 375px;
    --max-height: 740px;
    --width: 100%;
    --height: 100%;
    --background: unset;
  }

  .patient-quota-control-modal {
    --max-width: 375px;
    --max-height: 740px;
    --width: 100%;
    --height: 100%;
    --background: unset;
  }

  .adjust-plan-modal {
    --max-width: 375px;
    --max-height: 740px;
    --width: 100%;
    --height: 100%;
    --background: unset;
  }

  .how-to-archive-modal {
    --max-width: 375px;
    --max-height: 740px;
    --width: 100%;
    --height: 100%;
    --background: unset;
  }

  .archive-control-modal {
    --max-width: 375px;
    --max-height: 740px;
    --width: 100%;
    --height: 100%;
    --background: unset;
  }

  .video-modal {
    --width: 800px;
    --height: 456px;
    --background: unset;
  }

  .manifesto-modal {
    --width: 100%;
    --height: 100%;
  }

  .matchmaking-teaser-modal {
    --width: 100%;
    --height: 100%;
  }
}

@media (min-width: 992px) {
  .subscribe-to-print-modal {
    --width: 800px;
    --height: 480px;
    --background: unset;
  }

  .welcome-modal {
    --width: 800px;
    --height: 480px;
    --background: unset;
  }

  .patient-quota-control-modal {
    --width: 800px;
    --height: 520px;
    --background: unset;
  }

  .adjust-plan-modal {
    --width: 800px;
    --height: 480px;
    --background: unset;
  }

  .how-to-archive-modal {
    --width: 800px;
    --height: 480px;
    --background: unset;
  }

  .archive-control-modal {
    --width: 800px;
    --height: 480px;
    --background: unset;
    &::part(content){
      overflow: visible;
    }
  }

  .video-modal {
    --width: 800px;
    --height: 456px;
    --background: unset;
  }

  .manifesto-modal {
    --width: 865px;
    --height: 90%;
  }

  .matchmaking-teaser-modal {
    --width: 40%;
    --height: 90%;
  }
}

@media (min-width: 1200px) {
  .subscribe-to-print-modal {
    --width: 960px;
    --height: 480px;
  }

  .welcome-modal {
    --width: 960px;
    --height: 480px;
  }

  .patient-quota-control-modal {
    --width: 960px;
    --height: 480px;
  }

  .adjust-plan-modal {
    --width: 1000px;
    --height: 530px;
  }

  .how-to-archive-modal {
    --width: 1000px;
    --height: 530px;
  }

  .archive-control-modal {
    --width: 870px;
    --height: 530px;
  }

  .video-modal {
    --width: 1000px;
    --height: 568px;
  }

  .manifesto-modal {
    --width: 1000px;
    --height: 90%;
  }
}

@media (min-width: 1440px) {
  .video-modal {
    --width: 1200px;
    --height: 680px;
  }
}
