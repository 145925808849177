.btn-rounded-2 {
  --border-radius: 2px;
  border-radius: 2px;
}

.btn-rounded-5 {
  --border-radius: 5px;
  border-radius: 5px;
}

.btn-rounded-10 {
  --border-radius: 10px;
  border-radius: 10px;
}

.btn-raised {
  --box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.btn-shadowed {
  --box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.btn-blue {
  --border-width: 0;
  --background: var(--ion-color-cogniblue);
  --color: white;
  --box-shadow: 0 0 0 0;
  opacity: 1;
}

.btn-blue-outline {
  --border-style: solid;
  --border-width: 2px;
  --border-color: var(--ion-color-cogniblue);
  --background: white;
  --color: var(--ion-color-cogniblue);
  opacity: 1;
}

.btn-orange-outline {
  --border-style: solid;
  --border-width: 2px;
  --border-color: var(--ion-color-cogniorange);
  --background: white;
  --color: var(--ion-color-cogniorange);
  opacity: 1;
}

.btn-grey-outline {
  --border-style: solid;
  --border-width: 2px;
  --border-color: var(--ion-color-cognigrey);
  --background: white;
  --color: var(--ion-color-cognigrey);
  opacity: 1;
}

.btn-white-outline {
  --border-style: solid;
  --border-width: 2px;
  --border-color: var(--ion-color-cogniwhite);
  --background: white;
  --color: var(--ion-color-cogniwhite);
  opacity: 1;
}

.btn-stone-outline {
  --border-style: solid;
  --border-width: 2px;
  --border-color: var(--ion-color-cognistone);
  --background: white;
  --color: var(--ion-color-cognistone);
  opacity: 1;
}

.btn-snow-shadowed {
  --border-width: 0;
  --background: var(--ion-color-cognisnow);
  --color: var(--ion-color-cognicharcoal);
  --box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}