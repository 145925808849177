.ipt-with-border {
  border: 2px solid var(--ion-color-cognigrey);
  box-sizing: border-box;
  --padding-start: 10px;
  --placeholder-color: var(--ion-color-cogniplaceholderok);
  --placeholder-opacity: 1;
  color: black;
}

.ipt-with-border-error {
  border: 2px solid var(--ion-color-cognired);
  box-sizing: border-box;
  --padding-start: 10px;
  --placeholder-color: var(--ion-color-cogniplaceholdererror);
  --placeholder-opacity: 1;
  --color: var(--ion-color-cognired);
}

