/* ITCSS Settings Files */
@import "./settings.colors.scss";
@import "./settings.typography.scss";

/* ITCSS Tools Files */

/* ITCSS Generic Files */
@import "./generic.position.scss";

/* ITCSS Elements Files */
@import "./elements.html.scss";
@import "./elements.ionic.scss";

/* ITCSS Objects Files */
@import "./objects.layout.scss";
@import "./objects.modals.scss";
@import "./objects.overlays.scss";

/* ITCSS Components Files */
@import "./components.buttons.scss";
@import "./components.inputs.scss";
@import "./components.selects.scss";

/* ITCSS Utilities Files */
@import "./utils.copy-and-paste";
