* {
  font-family: 'Open Sans';
  --ion-default-font: 'Open Sans';
  font-style: normal;
  font-weight: normal;
}

html {
  --ion-font-family: var(--ion-default-font);
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.f300 {
  font-weight: 300;
}

.f400 {
  font-weight: 400;
}

.f600 {
  font-weight: 600;
}

.f700 {
  font-weight: 700;
}

.fbold {
  font-weight: bold;
}

.fCft {
  font-family: 'Comfortaa';
}

.f8 {
  font-size: 0.5rem;
}

.f10 {
  font-size: 0.625rem;
}

.f12 {
  font-size: 0.75rem;
}

.f14 {
  font-size: 0.875rem;
}

.f16 {
  font-size: 1rem;
}

.f18 {
  font-size: 1.125rem;
}

.f20 {
  font-size: 1.25rem;
}

.f22 {
  font-size: 1.375rem;
}

.f24 {
  font-size: 1.5rem;
}

.f26 {
  font-size: 1.625rem;
}

.f28 {
  font-size: 1.75rem;
}

.f30 {
  font-size: 1.875rem;
}

.f32 {
  font-size: 2rem;
}










// TODO remover
.fnt-600 {
  font-family: 'Open Sans';
  font-weight: 600;
}
// TODO remover
.fnt-bold {
  font-family: 'Open Sans';
  font-weight: bold;
}
// TODO remover
.fnt-Cft {
  font-family: 'Comfortaa';
}
// TODO remover
.fnt-Cft-300 {
  font-family: 'Comfortaa';
  font-weight: 300;
}
// TODO remover
.fnt-Cft-600 {
  font-family: 'Comfortaa';
  font-weight: 600;
}
// TODO remover
.fnt-Cft-bold {
  font-family: 'Comfortaa';
  font-weight: bold;
}
